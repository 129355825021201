import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Home
  { path: 'about', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'HOMEPAGE' } },
  { path: 'abouthome', loadChildren: () => import('./components/pages/abouthome/abouthome.module').then(m => m.AboutHomeModule), data: { breadcrumb: 'ABOUT' } },
  { path: 'home-v2', loadChildren: () => import('./components/pages/home-v2/home-v2.module').then(m => m.HomeV2Module), data: { breadcrumb: 'HOMEPAGE' } },
  { path: 'home-v3', loadChildren: () => import('./components/pages/home-v3/home-v3.module').then(m => m.HomeV3Module), data: { breadcrumb: 'HOMEPAGE' } },
  { path: 'teammembers', loadChildren: () => import('./components/pages/teammembers/teammembers.module').then(m => m.TeammembersModule), data: { breadcrumb: 'OUR TEAM' } },
  { path: 'cfp', loadChildren: () => import('./components/pages/cfp/cfp.module').then(m => m.CfpModule), data: { breadcrumb: 'CFP' } },
  { path: 'cfpreviewboard', loadChildren: () => import('./components/pages/cfpreview/cfpreview.module').then(m => m.CfpreviewModule), data: { breadcrumb: 'CFP REVIEW BOARD' } },
  { path: 'ctf', loadChildren: () => import('./components/pages/ctf/ctf.module').then(m => m.CtfModule), data: { breadcrumb: 'CTF' } },
  { path: 'coc', loadChildren: () => import('./components/pages/coc/coc.module').then(m => m.CocModule), data: { breadcrumb: 'CODE OF CONDUCT' } },
  { path: 'trainings', loadChildren: () => import('./components/pages/trainings/training.module').then(m => m.TrainingModule), data: { breadcrumb: 'TRAININGS' } },
  { path: 'volunteers', loadChildren: () => import('./components/pages/volunteers/volunteers.module').then(m => m.VolunteersModule), data: { breadcrumb: 'VOLUNTEERS' } },
  { path: 'workshops', loadChildren: () => import('./components/pages/workshops/workshops.module').then(m => m.WorkshopsModule), data: { breadcrumb: 'WORKSHOPS' } },
   { path: 'privacypolicy', loadChildren: () => import('./components/pages/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule), data: { breadcrumb: 'PRIVACY POLICY' } },
  { path: 'registersponsor', loadChildren: () => import('./components/pages/registersponsor/registersponsor.module').then(m => m.RegistersponsorModule), data: { breadcrumb: 'REGISTER SPONSOR' } },
  { path: 'registermember', loadChildren: () => import('./components/pages/registermember/registermember.module').then(m => m.RegistermemberModule), data: { breadcrumb: 'BECOME A MEMBER' } },
 // { path: 'dummyregister', loadChildren: () => import('./components/pages/dummyregister/dummyregister.module').then(m => m.DummyregisterModule), data: { breadcrumb: 'EVENT REGISTRATION PLANS' } },
 // { path: 'eventregistration', loadChildren: () => import('./components/pages/eventregistration/eventregistration.module').then(m => m.EventregistrationModule), data: { breadcrumb: 'CONFERENCE REGISTRATION PLANS' } },
  { path: 'eventregistration', loadChildren: () => import('./components/pages/eventregistration/eventregistration.module').then(m => m.EventregistrationModule), data: { breadcrumb: 'MEETUP REGISTRATION PLANS' } },
  { path: 'conferenceregistration', loadChildren: () => import('./components/pages/conferenceregistration/conferenceregistration.module').then(m => m.ConferenceregistrationModule), data: { breadcrumb: 'CONFERENCE REGISTRATION PLANS' } },
  { path: 'villages', loadChildren: () => import('./components/pages/villages/villages.module').then(m => m.VillagesModule), data: { breadcrumb: 'VILLAGES' } },
  { path: 'toolsforge', loadChildren: () => import('./components/pages/toolsforge/toolsforge.module').then(m => m.ToolsforgeModule), data: { breadcrumb: 'TOOLSFORGE' } },
  { path: 'cxoroundtable', loadChildren: () => import('./components/pages/cxoroundtable/cxoroundtable.module').then(m => m.CxoroundtableModule), data: { breadcrumb: 'CxO ROUND TABLE' } },
  { path: 'discountcoupons', loadChildren: () => import('./components/pages/discountcoupons/discountcoupons.module').then(m => m.DiscountcouponsModule), data: { breadcrumb: 'COUPON CODES' } },
  { path: 'awardjury', loadChildren: () => import('./components/pages/awardjury/awardjury.module').then(m => m.AwardjuryModule), data: { breadcrumb: 'AWARD JURY' } },
  // About
  { path: '', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'ABOUT US' } },
  // Speakers
  { path: 'speakers', loadChildren: () => import('./components/pages/speakers/speakers.module').then(m => m.SpeakersModule), data: { breadcrumb: 'SPEAKERS' } },
  { path: 'speaker-details/:id', loadChildren: () => import('./components/pages/speaker-details/speaker-details.module').then(m => m.SpeakerDetailsModule), data: { breadcrumb: 'SPEAKER PROFILE' } },
  { path: 'ticketcheckin/:id', loadChildren: () => import('./components/pages/ticketcheckin/ticketcheckin.module').then(m => m.TicketcheckinModule), data: { breadcrumb: 'ticketcheckin' } },
  // Events
  { path: 'event', loadChildren: () => import('./components/pages/event/event.module').then(m => m.EventModule), data: { breadcrumb: 'SCHEDULE' } },
  { path: 'event-details/:id', loadChildren: () => import('./components/pages/event-details/event-details.module').then(m => m.EventDetailsModule), data: { breadcrumb: 'Training Details' }},
  { path: 'event-details-mock/:id', loadChildren: () => import('./components/pages/event-details-mock/event-details-mock.module').then(m => m.EventDetailsMockModule), data: { breadcrumb: 'Mock' } },
  // Pages
  //{ path: 'shop/cat/:catId', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: 'Shop' } },
  //{ path: 'shop/tag/:tagId', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: 'Shop' } },
  
  //{ path: 'shop', loadChildren: () => import('./components/pages/shop/shop.module').then(m => m.ShopModule), data: { breadcrumb: 'Shop' } },
  //{ path: 'shop-details/:id', loadChildren: () => import('./components/pages/shop-details/shop-details.module').then(m => m.ShopDetailsModule), data: { breadcrumb: 'Shop Details' } },
  //{ path: 'schedulesv3', loadChildren: () => import('./components/pages/schedules/schedules.module').then(m => m.SchedulesModule), data: { breadcrumb: 'SCHEDULES' } },
  { path: 'schedules', loadChildren: () => import('./components/pages/scheduletraining/scheduletraining.module').then(m => m.ScheduletrainingModule), data: { breadcrumb: 'SCHEDULES' } },
  { path: 'schedulesmeetup', loadChildren: () => import('./components/pages/schedulev/schedulev.module').then(m => m.SchedulevModule), data: { breadcrumb: 'MEETUP SCHEDULE' } },
 // { path: 'shedules-v2', loadChildren: () => import('./components/pages/schedule-details/schedule-details.module').then(m => m.ScheduleDetailsModule), data: { breadcrumb: 'Schedules v2' } },
  { path: 'sponsors', loadChildren: () => import('./components/pages/sponser/sponser.module').then(m => m.SponserModule), data: { breadcrumb: 'SPONSORS' } },
  { path: 'partners', loadChildren: () => import('./components/pages/partners/partners.module').then(m => m.PartnersModule), data: { breadcrumb: 'COMMUNITY PARTNERS' } },
  { path: 'bsidesbangalore2023', loadChildren: () => import('./components/pages/gallery/gallery.module').then(m => m.GalleryModule), data: { breadcrumb: 'Gallery' } },
  { path: 'walloffame', loadChildren: () => import('./components/pages/walloffame/walloffame.module').then(m => m.WalloffameModule), data: { breadcrumb: 'Wall of Fame' } },
  { path: 'gallery-v2', loadChildren: () => import('./components/pages/gallery-two/gallery-two.module').then(m => m.GalleryTwoModule), data: { breadcrumb: 'Gallery v2' } },
 // { path: 'pricing', loadChildren: () => import('./components/pages/pricing/pricing.module').then(m => m.PricingModule), data: { breadcrumb: 'Pricing' } },
  //{ path: 'faq', loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule), data: { breadcrumb: "FAQ's" } },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'CONTACT US' } },
  { path: 'ticketprint', loadChildren: () => import('./components/pages/ticketprint/ticketprint.module').then(m => m.TicketprintModule), data: { breadcrumb: 'Ticket Details' } },
  { path: 'registertraining/:id', loadChildren: () => import('./components/pages/trainingregistration/trainingregistration.module').then(m => m.TrainingregistrationModule), data: { breadcrumb: 'Training Plans' } },
  { path: 'trainingpass', loadChildren: () => import('./components/pages/trainingpass/trainingpass.module').then(m => m.TrainingpassModule), data: { breadcrumb: 'Training Pass Details' } },
  { path: 'backendregisterfill', loadChildren: () => import('./components/pages/backendregisterfill/backendregisterfill.module').then(m => m.BackendregisterfillModule), data: { breadcrumb: 'backendregisterfill' } },
  //{ path: 'coming-soon', loadChildren: () => import('./components/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule), data: { breadcrumb: 'Coming Soon' } },
  // News
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog-grid-two/blog-grid-two.module').then(m => m.BlogGridTwoModule), data: { breadcrumb: 'Blog' } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog-grid-two/blog-grid-two.module').then(m => m.BlogGridTwoModule), data: { breadcrumb: 'Blog' } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog-grid-two/blog-grid-two.module').then(m => m.BlogGridTwoModule), data: { breadcrumb: 'Blog' } },
  { path: 'paylink', loadChildren: () => import('./components/pages/paylink/paylink.module').then(m => m.PaylinkModule), data: { breadcrumb: 'Paylink' } },
  { path: 'careerguide', loadChildren: () => import('./components/pages/careerguide/careerguide.module').then(m => m.CareerguideModule), data: { breadcrumb: 'Career Guide' } },
  { path: 'jobfair', loadChildren: () => import('./components/pages/jobfair/jobfair.module').then(m => m.JobfairModule), data: { breadcrumb: 'Job Fair' } },
  
 // { path: 'blog-standard', loadChildren: () => import('./components/pages/blog-list/blog-list.module').then(m => m.BlogListModule), data: { breadcrumb: 'Blog Standard' } },
 // { path: 'blog-grid', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'blogs', loadChildren: () => import('./components/pages/blog-grid-two/blog-grid-two.module').then(m => m.BlogGridTwoModule), data: { breadcrumb: 'Blog' } },
  { path: 'awards', loadChildren: () => import('./components/pages/awards/awards.module').then(m => m.AwardsModule), data: { breadcrumb: 'Awards' } },
  { path: 'emergex', loadChildren: () => import('./components/pages/emergex/emergex.module').then(m => m.EmergexModule), data: { breadcrumb: 'Emergex:Innovation' } },
  { path: 'award-details/:id', loadChildren: () => import('./components/pages/award-details/award-details.module').then(m => m.AwarddetailsModule), data: { breadcrumb: 'Award Details' } },
  { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: 'Blog Details' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
